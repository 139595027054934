const { conf } = require('./configService');
const { domainService } = require('./domainService');

// TODO: make this configurable
const disabledUrlsForInstantNavigation = {
  br: [
    '/alem-do-grao/',
    '/the-hot-zone',
    '/explore-o-oceano-em-24-horas',
    '/meio-ambiente/perpetual-planet'
  ],
  mx: [
    '/mas-alla-del-grano-de-cafe/',
    '/the-hot-zone',
    '/explora-el-oceano-en-24-horas',
    '/medio-ambiente/perpetual-planet'
  ],
  pt: [
    '/para-alem-do-grao/',
    '/the-hot-zone',
    '/explore-o-oceano-em-24-horas',
    '/os-nossos-exploradores',
    '/politica-cookies',
    '/meio-ambiente/perpetual-planet'
  ],
  es: [
    '/mas-alla-del-grano/',
    '/the-hot-zone',
    '/explora-el-oceano-en-24-horas',
    '/exploradores-national-geographic',
    '/politica-de-cookies',
    '/medio-ambiente/perpetual-planet',
    '/disney-plus-todo-lo-que-necesitas-saber'
  ],
  fr: [
    '/au-dela-du-grain/',
    '/the-hot-zone',
    '/explorez-les-oceans-en-24-heures',
    '/savourez-les-vertus-des-eaux-slovenes',
    '/explorateurs-ng',
    '/les-cookies',
    '/environnement/perpetual-planet',
    '/jerusalem',
    '/disney-plus-tout-ce-que-vous-devez-savoir'
  ],
  de: [
    '/hinter-der-bohne/',
    '/the-hot-zone',
    '/der-ozean-in-24-stunden',
    '/reise-und-abenteuer/2019/07/explore-der-national-geographic-podcast',
    '/unsere-explorer',
    '/cookie-richtlinie',
    '/umwelt/perpetual-planet',
    '/disney-plus-alles-was-ihr-wissen-muesst'
  ],
  uk: [
    '/beyond-the-bean/',
    '/the-hot-zone',
    '/explore-the-ocean-in-24-hours',
    '/meet-the-explorers',
    '/cookie-policy',
    '/environment/perpetual-planet',
    '/introducing-disney-plus'
  ]
};

const urlService = {
  hasInstantNavigationDisabled: url => {
    const disabledUrlsByCountry =
      disabledUrlsForInstantNavigation[conf.siteCountryCode];
    return !!disabledUrlsByCountry.find(
      disabledUrl => url.indexOf(disabledUrl) !== -1
    );
  },

  isExternal: url => {
    const absoluteUrl = urlService.makeAbsolute(url);
    const { hostname } = new URL(absoluteUrl);
    return (
      hostname !== domainService.getCurrentDomain() &&
      hostname !== conf.canonicalDomain
    );
  },

  makeAbsolute: relativeUrl => {
    // TODO DELETE
    if (urlService.isAbsolute(relativeUrl)) {
      return relativeUrl;
    }
    const relativeUrlWithLeadingSlash = `/${relativeUrl}`.replace('//', '/');
    const currentOrigin = domainService.getCurrentOrigin();
    return `${currentOrigin}${relativeUrlWithLeadingSlash}`;
  },

  isAbsolute: url => url.match(/^https?:\/\//i),

  isRelative: url => !urlService.isAbsolute(url)
};

module.exports = { urlService };
